%is-hidden {
  display: none;
}

.flex {
  @extend %flexbox;
  background: #606060;
}

  .flex__item {
    flex: 1;
  }

  .flex__item--info {
      padding: 1em;
  }

  .flex__item--map {
    flex: 2;
  }

.field {
  @extend %flexbox;
}

.field__elm {
  @extend %flex__elm-margin;
  flex: 1 0 10em;
}

.field__elm--smaller {
  @extend %flex__elm-margin;
  flex: 1 0 1em;
}

.results__elm {
  font-size: 30px;
  color: #fff;
}

.results__elm--price {
  font-size: 40px;
}

.wrapper {
  width: 80%;
  padding: 1em;
  margin: auto;
}

.calc-wrapper {
  @extend %is-hidden;
  &.is-active {
    display: block;
  }
}

.results-container {
  @extend %is-hidden;
  &.is-active {
    display: block;
  }
}

.results {
  margin: 1em 0 0 0;
}

.results__heading {
  color: #fff;
  margin: 0;
} 

.results__details {
  color: #fff;
  font-size: 14px;
  font-weight: 100;
} 

