input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"],
select,
textarea {
    width: 100%;
    padding: 0.3em 0.5em;
    display: inline-block;
    background-color: #fff;
    border: 0;
    box-shadow: inset 0 1px 3px #ddd;
    border-radius: 0;
    vertical-align: middle;

    font-family: 'Aileron-Regular';
    font-size: 14px;
    color: #606060;
    letter-spacing: 0.12px;
    line-height: 30px;

    &:focus {
      outline: 0;
      border-color: #ddd;
    }
}

input:not([type]) {
    @extend input[type="text"];
}

input[type="checkbox"]:focus {
    outline: 0;
}

