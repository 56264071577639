img {
    max-width: 100%;
    height: auto;
}

sub {
  color: #fff;
  font-size: x-large;
  bottom: 0;
}

label {
  margin-bottom: 0.2em;
  display: inline-block;
  color: #fff;
  line-height: 30px;
}