.switch-toggle a,
.switch-light span span {
  display: none; 
}

@media only screen {
    /* Checkbox */
    .switch-light {
      /* simulate default browser focus outlines on the switch, when the inputs are focused.*/
      position: relative;
      display: block;
      width: 80px;
      float: right;
      
      &::after {
        clear: both;
        content: "";
        display: table; 
      }
    }
    
    .switch-light a {
      display: block;
      transition: all 0.2s ease-out; 
    }

    .switch-light label,
    .switch-light > span {
      line-height: 2em;
      vertical-align: middle;
    }
      .switch-light input:focus ~ span a,
      .switch-light input:focus + label {
        outline-width: 2px;
        outline-style: solid;
        outline-color: transparent;
        } 
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 0) {
      .switch-light input:focus ~ span a,
      .switch-light input:focus + label {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto; 
      } 
    }

@media only screen {
    /* don't hide the input from screen-readers and keyboard access */
    .switch-light input {
      position: absolute;
      opacity: 0;
      z-index: 3; 
    }

    .switch-light input:checked ~ span a {
      right: 0%; 
    }
    
    .switch-light > span {
      position: relative;
      overflow: hidden;
      display: block;
      min-height: 2em;
      text-align: left;
    }

    .switch-light span span {
      position: relative;
      z-index: 2;
      display: block;
      float: left;
      width: 50%;
      text-align: center;
      user-select: none; 
    }

    .switch-light a {
      position: absolute;
      right: 50%;
      top: 0;
      z-index: 1;
      display: block;
      width: 50%;
      height: 100%;
      padding: 0; 
    }
    
    .switch-toggle.switch-candy,
    .switch-light.switch-candy > span {
      background: #B5B5B5;
      box-shadow: 0 0 1px 0 rgba(0,0,0,0.50);
      border-radius: 5px;
    }

    .switch-light.switch-candy span span,
    .switch-light.switch-candy input:checked ~ span span:first-child,
    .switch-toggle.switch-candy label {
      color: #fff;
      text-align: center;
    }

    .switch-light.switch-candy input ~ span span:first-child,
    .switch-light.switch-candy input:checked ~ span span:nth-child(2),
    .switch-candy input:checked + label {
      color: #fff;
    }

    .switch-candy a {
      border: 1px solid transparent;
      border-radius: 3px;
      box-shadow: 0 0 1px 0 rgba(0,0,0,0.50);
      background-color: #ffbd16;
      background-image: -webkit-linear-gradient(top, #ffbd16, transparent);
      background-image: linear-gradient(to bottom, #ffbd16, transparent); 
    }
    .switch-candy-blue a {
      background-color: #B5B5B5; 
    }

    .switch-candy-yellow a {
      background-color: #ffbd16; 
    }
  
  /* Bugfix for older Webkit, including mobile Webkit. Adapted from http://css-tricks.com/webkit-sibling-bug/ */
  @media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
    .switch-light,
    .switch-toggle {
      -webkit-animation: webkitSiblingBugfix infinite 1s; 
    } 
  }

  @-webkit-keyframes webkitSiblingBugfix {
    from {
      -webkit-transform: translate3d(0, 0, 0);
    }
    to {
      -webkit-transform: translate3d(0, 0, 0); 
    } 
  }
}