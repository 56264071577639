/* Container used for styling the custom select, the buttom class below adds the
 * bg gradient, corners, etc. */
.custom-select {
	position: relative;
	display: block;
}

/* This is the native select, we're making everything but the text invisible so
 * we can see the button styles in the wrapper */
.custom-select select {
	width: 100%;
	margin: 0;
	outline: none;
	padding: 0.2em 0.5em;

	/* Font size must be 16px to prevent iOS page zoom on focus */
	font-size: 16px;
}


/* Custom arrow sits on top of the select - could be an image, SVG, icon font,
 * etc. or the arrow could just baked into the bg image on the select. */
.custom-select::after {
	content: " ";
	position: absolute;
	top: 50%;
	right: 1em;
	z-index: 2;
	/* These hacks make the select behind the arrow clickable in some browsers */
	pointer-events: none;
	display: none;
}

.custom-select::after {
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 7px solid #666;
	margin-top: -3px;
}

/* Firefox <= 34 has a false positive on @supports( -moz-appearance: none )
 * @supports ( mask-type: alpha ) is Firefox 35+
 */
// @supports (-webkit-appearance: none) or (appearance: none) or ((-moz-appearance: none) and (mask-type: alpha)) {

	/* Show custom arrow */
	.custom-select::after {
		display: block;
	}

	/* Remove select styling */
	.custom-select select {
		padding-right: 2em; /* Match-01 */
		/* inside @supports so that iOS <= 8 display the native arrow */
    background: white; /* Match-04 */
    color: #606060;
		/* inside @supports so that Android <= 4.3 display the native arrow */
		border: 1px solid transparent; /* Match-05 */

		-webkit-appearance: none;
		-moz-appearance: none;
    appearance: none;
    &:focus {
	  	border-color: #aaa; /* Match-03 */
	  }
  }