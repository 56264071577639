%o-wrapper {
  @include grid-collapse;
}

%o-container {
  @include grid-container;
}

%flex__elm-margin {
  margin: 0 1em 0 0;
  &:last-child() {
    margin: 0 0 0 1em;
  }
} 

%flexbox {
  display: flex;
}