.align-center {
  text-align: center !important;
}

.float-right {
  float: right !important;
}

.capitalize {
  text-transform: uppercase;
}

.msg {
  display: none;
  color: #fff;
  &.is-visible {
    display: block;
  }
}

.error {
  display: none;
  color: red;
  &.is-visible {
    display: block;
  }
}