$border-width: 1px !default;


$btn-padding-x: .5rem !default;
$btn-padding-y: .75rem !default;

$fonts-folder: '../fonts';

$aileron-folder: 'Aileron';

$base-font-size: 1rem;
$base-line-height: $base-font-size * 1.25;

// options
$option-size:            1em;
$explosion-distance:     5; // multiplied by $option-size
$explosion-duration:     0.65s;



$type-scale: (
  -1: 0.75rem,  // small text
  0: 1rem,      // body text
  1: 1.333rem,  // large text
  2: 1.777rem   // main heading
);

$line-height: (
  -1: $base-line-height,
  0: $base-line-height,
  1: $base-line-height * 2,
  2: $base-line-height * 2
);

