.c-btn {
  display: inline-block;
  padding: 0.4em 0.5em 0.3em 0.5em;
  border: $border-width solid transparent;
  border-radius: 0.2em;
  color: $white;
  transition: background-color 0.2s;
  box-shadow: 0 0 1px 0 rgba(0,0,0,0.50);
  cursor: pointer;

  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;

  &:focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &:disabled {
    opacity: .65;
  }

  &:active {
    background-image: none;
  }
}

.c-btn--cancel {
  background-color: $icon-grey;
  &:hover {
    background-color: $icon-selected-grey;
  }
}

.c-btn--primary {
  background-color: #ffbd16;
  &:hover {
    background-color: tint(#ffbd16, 10%);
  }
}

.c-btn--search {
  border-radius: 0 5px 5px 0;
  background: #ffbd16;
  color: #EDFFEC;
  padding: .35em .75em;
  border: none;
  font-size: 1.1rem;
  box-shadow: none;
  margin: 0;
}

.c-btn--full {
  width: 100%;
  margin: 1em 0;
}

.c-btn--cta {
  width: 100%;
  height: 60px;
  margin-top: 1em;
  &:hover {
     background:rgba(255,188,8,0.80);
  }
}

.c-btn--restart {
  @extend .c-btn--full;
  border: 2px solid #ffbd16;
  border-radius: 5px;
  background: transparent;
  color: #ffbd16;
}




