h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Aileron-Regular";
  line-height: 32px;
  font-weight: 100;
}

// The main heading
h1 { 
  // @include type-setting(2);
  font-size: 2em;
  color: #fff;
  letter-spacing: 6px;
}

// The smaller top heading
h2 { @include type-setting(-1); }

