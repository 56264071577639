$primary-red         : #dd4B37 !default;
$primary-yellow      : #faa424 !default;
$white               : #ffffff !default;
$light-grey-text     : #9b9b9b !default;
$dark-grey-text      : #4a4a4a !default;
$orange-text         : #f37021 !default;
$hyperlink-orange    : #faa424 !default;

$list-grey           : rgba(217, 217, 217, 0.25) !default;
$list-selected-grey  : rgba(217, 217, 217, 0.5) !default;
$dark-grey           : rgba(80, 80, 82, 1) !default;
$divider             : rgba(0, 0, 0, 0.12) !default;
$bg-grey             : rgba(240, 240, 240, 1) !default;
$orange              : rgba(255, 151, 31, 1) !default;
$green               : rgba(151, 184, 102, 1) !default;
$icon-grey           : rgba(136, 136, 136, 1) !default;
$icon-selected-grey  : rgba(74, 74, 74, 1) !default;

$option-color        : #cbd1d8 !default;
$checked-option-color: $orange !default;
$bg-menu             : #333333 !default;

