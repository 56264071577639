.input-box {
  // Shrink and grow as needed, but never shrink so
  // small that we can't read the placeholder
  flex: 1 0 8em;
  // Make sure the placeholder isn't poorly cut off
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.field--search:before {
  // Don't shrink or grow and use a default size
  flex: 0 0 2.75em;
  // Use flexbox to center the icon
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
