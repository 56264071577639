ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      padding: 1em 0;
      font-family: "Aileron-Regular";
      background: #606060;
      color: #fff;
      letter-spacing: 0.05px;
      line-height: 30px;
    }
}

ol {
    margin: 0;
    padding: 0;
}

li > {

    ul,
    ol {
        list-style: square outside;
    }

}
